import React from 'react';
import { Button } from '@mui/material';

const fontThickness = 600;

const colorScheme = {
  prelim: {
    backgroundColor: 'darkgreen',
    color: 'white',
    fontWeight: fontThickness,
  },
  modified: {
    backgroundColor: 'darkblue',
    color: 'orange',
    fontWeight: fontThickness,
  },
  training: {
    backgroundColor: 'black',
    color: 'white',
    fontWeight: fontThickness,
  },
  novice: {
    backgroundColor: 'white',
    color: 'black',
    fontWeight: fontThickness,
  },
  beginnerNovice: {
    backgroundColor: 'yellow',
    color: 'black',
    fontWeight: fontThickness,
  },
  starter: {
    backgroundColor: '#0099ff',
    color: 'white',
    fontWeight: fontThickness,
  },
  preStarter: {
    backgroundColor: 'purple',
    color: 'white',
    fontWeight: fontThickness,
  },
};

export function prelimMapButton() {
  return (
    <Button
      style={colorScheme.prelim}
      href="/maps/Prelim.jpg"
      sx={{ borderRadius: 28 }}
    >
      Preliminary
    </Button>
  );
}

export function prelimOptimumButton(time, href) {
  return (
    <Button style={colorScheme.prelim} href={href} sx={{ borderRadius: 28 }}>
      {time}
    </Button>
  );
}

export function modifiedMapButton() {
  return (
    <Button
      style={colorScheme.modified}
      href="/maps/Modified.jpg"
      sx={{ borderRadius: 28 }}
    >
      Modified
    </Button>
  );
}

export function modifiedOptimumButton(time, href) {
  return (
    <Button style={colorScheme.modified} href={href} sx={{ borderRadius: 28 }}>
      {time}
    </Button>
  );
}

export function trainingMapButton() {
  return (
    <Button
      style={colorScheme.training}
      href="/maps/Training.jpg"
      sx={{ borderRadius: 28 }}
    >
      Training
    </Button>
  );
}

export function trainingOptimumButton(time, href) {
  return (
    <Button style={colorScheme.training} href={href} sx={{ borderRadius: 28 }}>
      {time}
    </Button>
  );
}

export function noviceMapButton() {
  return (
    <Button
      style={colorScheme.novice}
      href="/maps/Novice.jpg"
      sx={{ borderRadius: 28 }}
    >
      Novice
    </Button>
  );
}

export function noviceOptimumButton(time, href) {
  return (
    <Button style={colorScheme.novice} href={href} sx={{ borderRadius: 28 }}>
      {time}
    </Button>
  );
}

export function begNoviceMapButton() {
  return (
    <Button
      style={colorScheme.beginnerNovice}
      href="/maps/BegNovice.jpg"
      sx={{ borderRadius: 28 }}
    >
      Beg Novice
    </Button>
  );
}

export function begNoviceOptimumButton(time, href) {
  return (
    <Button
      style={colorScheme.beginnerNovice}
      href={href}
      sx={{ borderRadius: 28 }}
    >
      {time}
    </Button>
  );
}

export function starterMapButton() {
  return (
    <Button
      style={colorScheme.starter}
      href="/maps/Starter.jpg"
      sx={{ borderRadius: 28 }}
    >
      Starter
    </Button>
  );
}

export function starterOptimumButton(time, href) {
  return (
    <Button style={colorScheme.starter} href={href} sx={{ borderRadius: 28 }}>
      {time}
    </Button>
  );
}

export function preStarterMapButton() {
  return (
    <Button
      style={colorScheme.preStarter}
      href="/maps/Starter.jpg"
      sx={{ borderRadius: 28 }}
    >
      PreStarter
    </Button>
  );
}

export function preStarterOptimumButton(href) {
  return (
    <Button
      style={colorScheme.preStarter}
      href={href}
      sx={{ borderRadius: 28 }}
    >
      N/A
    </Button>
  );
}
