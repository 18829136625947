import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { MoreVertical } from 'react-feather';

import { fetchDivisions } from '../../../redux/actions/Events/entry.actions';
import { useDispatch, useSelector } from 'react-redux';
import DivisionForm from '../DivisionForm';

import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary'].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

function DivisionsPage() {
  const [selected, setSelected] = React.useState(-1);
  // const [selected, setSelected] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDivisions());
  }, [dispatch]);

  const divisions = useSelector((state) => state.divisions);
  const division = useSelector((state) =>
    state.divisions.find((x) => x.id === selected)
  );

  function typeToChip(type) {
    if (type === 0) {
      return <Chip label="Unassigned" color="danger" />;
    } else if (type === 1) {
      return <Chip label="Horse Trials" color="success" />;
    } else if (type === 2) {
      return <Chip label="Combined Test" color="info" />;
    } else if (type === 3) {
      return <Chip label="Dressage Only" color="warning" />;
    } else {
      return '';
    }
  }

  const handleClick = (event, id) => {
    setSelected(id);
  };

  const isSelected = (id) => selected === id;

  function mapBody(divisions) {
    if (divisions && divisions.length > 0) {
      const tableBody = divisions.map((division) => {
        const isItemSelected = isSelected(division.id);
        return (
          <TableRow
            key={division.id}
            aria-checked={isItemSelected}
            onClick={(event) => handleClick(event, division.id)}
            selected={isItemSelected}
          >
            <TableCell component="th" scope="row">
              {division.id}
            </TableCell>
            <TableCell>{division.name}</TableCell>
            <TableCell>{typeToChip(division.type)}</TableCell>
            <TableCell>{division.eventLevelName}</TableCell>
          </TableRow>
        );
      });
      return tableBody;
    }
  }

  function showDivisionForm() {
    if (selected && selected > 0) {
      if (division) {
        return <DivisionForm division={division} />;
      }
    }
  }

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Divisions"
      />
      <Paper>
        <TableWrapper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Division Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Level</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{mapBody(divisions)}</TableBody>
          </Table>
        </TableWrapper>
      </Paper>
      {showDivisionForm()}
    </Card>
  );
}

export default DivisionsPage;
