import axios from 'axios';
import { SALE_HORSE_FETCHED, SET_SALE_HORSES } from './types';
import { horseToClass, horsesToClassArray } from '../classes/Horse.class';

export function saleHorseFetched(salehorse) {
  return {
    type: SALE_HORSE_FETCHED,
    salehorse: horseToClass(salehorse),
  };
}

export function setSaleHorses(salehorses) {
  return {
    type: SET_SALE_HORSES,
    salehorses: horsesToClassArray(salehorses),
  };
}

export const fetchSaleHorses = () => async (dispatch) => {
  const res = await axios.get('/api/salehorses');
  dispatch(setSaleHorses(res.data));
};

export const fetchSaleHorse = (id) => async (dispatch) => {
  const res = await axios.get('/api/salehorses/' + id);
  dispatch(saleHorseFetched(res.data));
};
