import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  fetchHorse,
  createHorse,
  updateHorse,
} from '../../../../redux/actions/horse.actions';
import HorseSubForm from '../core/HorseSubForm';
import { useNavigate, useParams } from 'react-router-dom';
import HelmetTracker from '../../../core/HelmetTracker';

export default function HorseForm({ auth }) {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      document.title = 'Edit Horse';
      dispatch(fetchHorse(id));
    } else {
      document.title = 'Add Horse';
    }
  }, [dispatch, id]);

  const horse = useSelector(
    (state) =>
      state.horses.find((m) => {
        return m.id === parseInt(id);
      }),
    shallowEqual
  );
  const barns = useSelector((state) => state.barns);

  function navigateBack() {
    if (id) navigate('/horses/manage/' + id);
    else navigate('/horses');
  }

  function saveHorse(horse) {
    if (horse && horse.id) {
      return dispatch(updateHorse(horse)).then(() => {
        navigateBack();
      });
    } else {
      return dispatch(createHorse(horse)).then(() => {
        navigateBack();
      });
    }
  }

  const userId = auth ? auth.id : null;
  const title = horse ? 'Edit Horse' : 'Add Horse';
  const objId = horse ? horse.id : null;

  return (
    <>
      <HelmetTracker title={title} objId={objId} userId={userId} />
      <HorseSubForm
        horse={horse}
        barns={barns}
        submitHorse={saveHorse}
        auth={auth}
      />
    </>
  );
}
