import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  fetchEvent,
  createEvent,
  updateEvent,
} from '../../../redux/actions/Events/event.actions';
import EventSubForm from './core/EventSubForm';
import { useNavigate, useParams } from 'react-router-dom';
import HelmetTracker from '../../core/HelmetTracker';

function EventForm({ auth }) {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(fetchEvent(id));
    }
  }, [dispatch, id]);

  const event = useSelector(
    (state) =>
      state.events.find((m) => {
        return m.id === parseInt(id);
      }),
    shallowEqual
  );
  const selBarns = useSelector((state) => state.barns);

  function returnRoute() {
    if (id) return '/events/manage/' + id;
    else return '/events';
  }

  function saveEvent(event) {
    if (event && event.id) {
      return dispatch(updateEvent(event)).then(() => {
        navigate(returnRoute());
      });
    } else {
      return dispatch(createEvent(event)).then(() => {
        navigate(returnRoute());
      });
    }
  }

  const objId = event ? event.id : null;
  const title = event ? 'Edit Event' : 'Add Event';
  const userId = auth ? auth.id : null;

  return (
    <>
      <HelmetTracker title={title} objId={objId} userId={userId} />
      <EventSubForm event={event} barns={selBarns} submitEvent={saveEvent} />
    </>
  );
}

export default EventForm;
