import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import {
  okCancel,
  finalTextField,
  finalObjSelector,
  renderFormTitle,
  required,
  createOptionsList,
} from '../../core/FinalCoreForms';
import { useDispatch, useSelector } from 'react-redux';
import {
  createEventDivision,
  updateEventDivision,
  fetchDivisions,
} from '../../../redux/actions/Events/entry.actions';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const EventDivisionForm = ({ eventDivision, onOkClick }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDivisions());
  }, [dispatch]);
  const divisions = useSelector((state) => state.divisions);
  
  const selectedEventId = 115;

  function submitEventDivision(eventDivision) {
    if (eventDivision && eventDivision.id) {
      return dispatch(updateEventDivision(eventDivision)).then(() => {
        //   navigateBack();
      });
    } else {
      return dispatch(createEventDivision(eventDivision)).then(() => {
        //   navigateBack();
      });
    }
  }

  const initialValues = {
    id:
      eventDivision && eventDivision.id
        ? eventDivision.id
        : null,
    name:
      eventDivision && eventDivision.name
        ? eventDivision.name
        : '',
    divisionId:
      eventDivision && eventDivision.divisionId
        ? eventDivision.divisionId
        : 0,
  };

  const onSubmit = async (values) => {
    await sleep(300);
    if (eventDivision) {
      Object.assign(eventDivision, values);
      submitEventDivision(eventDivision);
    } else {
      let newData = {};
      Object.assign(newData, values);
      newData.eventId = selectedEventId;
      submitEventDivision(newData);
    }
    if (onOkClick) {
      onOkClick();
    }
  };

  // const typeOptionsList = [
  //   { key: 'Not Selected', value: '0' },
  //   { key: 'Horse Trials', value: '1' },
  //   { key: 'Combined Test', value: '2' },
  //   { key: 'Dressage Only', value: '3' },
  // ];

  return (
    <div>
      {renderFormTitle(eventDivision, 'Event Division')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {finalTextField('name', 'Division Name', required)}
            {finalObjSelector(
              'divisionId',
              'Division',
              createOptionsList(divisions)
            )}
            {okCancel(onOkClick)}
          </form>
        )}
      />
    </div>
  );
};

export default EventDivisionForm;
