import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchEvent } from '../../../redux/actions/Events/event.actions';
import {
  Col,
  Card,
  Button,
  Container,
  Alert,
  Row,
  Tab,
  Tabs,
  Badge,
} from 'react-bootstrap';
import { Easel } from 'react-bootstrap-icons';
import { LinkContainer } from 'react-router-bootstrap';
import EventDocumentManagementPanel from './core/EventDocumentManagementPanel';
import EventDetailsPanel from './core/EventDetailsPanel';
import XCCoursesTable from './forms/XCCoursesTable';
import SJCoursesTable from './forms/SJCoursesTable';
import HelmetTracker from '../../core/HelmetTracker';

export function EventManagement() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchEvent(id));
    }
  }, [dispatch, id]);

  const event = useSelector(
    (state) =>
      state.events.find((m) => {
        return m.id === parseInt(id);
      }),
    shallowEqual
  );

  const auth = useSelector((state) => state.auth);

  function getDocumentsCount() {
    if (event && event.docs) return event.docs.length;
    else return 0;
  }

  function getDocumentsTabTitle() {
    return (
      <React.Fragment>
        Documents &nbsp;
        <Badge bg="info">{getDocumentsCount()}</Badge>
      </React.Fragment>
    );
  }

  function getXCCoursesTabTitle() {
    return (
      <React.Fragment>
        XC Courses &nbsp;
        <Badge bg="info">
          {(event && event.xcCoursesArray) ? event.xcCoursesArray.length : 0}
        </Badge>
      </React.Fragment>
    );
  }

  function getSJCoursesTabTitle() {
    return (
      <React.Fragment>
        SJ Courses &nbsp;
        <Badge bg="info">
          {event && event.sjCoursesArray ? event.sjCoursesArray.length : 0}
        </Badge>
      </React.Fragment>
    );
  }

  function renderHeaderButton() {
    return (
      <LinkContainer to={'/events'}>
        <Button variant="info">Return to Events</Button>
      </LinkContainer>
    );
  }

  const userId = auth ? auth.id : null;
  const objId = event ? event.id : null;

  return (
    <Container fluid>
      <HelmetTracker title="Event Management" userId={userId} objId={objId} />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Alert variant="danger">
            <h3>
              <Easel /> &nbsp; &nbsp;Event Management Console
            </h3>
            <h4>
              {event ? event.name : ''}&nbsp; -&nbsp; (Id:
              {event ? event.id : ''})
            </h4>
            {renderHeaderButton()}
          </Alert>
        </Col>
      </Row>
      <Row className="show-grid">
        <Card border="danger">
          <Card.Body>
            <Tabs
              defaultActiveKey="details"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="details" title="Details">
                <EventDetailsPanel eventSelected={event} />
              </Tab>
              <Tab eventKey="documents" title={getDocumentsTabTitle()}>
                <EventDocumentManagementPanel
                  eventSelected={event}
                  auth={auth}
                />
              </Tab>
              <Tab eventKey="xcCourses" title={getXCCoursesTabTitle()}>
                <XCCoursesTable eventData={event} />
              </Tab>
              <Tab eventKey="sjCourses" title={getSJCoursesTabTitle()}>
                <SJCoursesTable eventData={event} />
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default EventManagement;
