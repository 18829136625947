import React from 'react';
import { Form } from 'react-final-form';
import {
  okCancel,
  finalTextArea,
} from '../../../core/FinalCoreForms';
import {
    createHorseLogItem,
} from '../../../../redux/actions/users/horseLog.actions';
import { useDispatch } from 'react-redux';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function HorseLogForm({ horse, auth, onOkClick }) {
  const dispatch = useDispatch();

  const initialValues = {
    log: '',
    horseId: horse ? horse.id : 0,
  };

  function submitHorseLog(horseLog) {
    // if (horseLog && horseLog.id) {
    //   return dispatch(updateHorseLog(horseLog)).then(() => {
    //     //   navigateBack();
    //   });
    // } else {
      return dispatch(createHorseLogItem(horseLog)).then(() => {
        //   navigateBack();
      });
    // }
  }

  const onSubmit = async (values) => {
    await sleep(300);
    // if (horseLog && submitHorseLog) {
    //   Object.assign(horseLog, values);
    //   horseLog.userId = auth.id;
    //   submitHorseLog(horseLog);
    // } else {
      let newData = {};
      Object.assign(newData, values);
      newData.userId = auth.id;
      console.log(newData);
      submitHorseLog(newData);
    // }
    if (onOkClick) {
      onOkClick();
    }
  };

  return (
    <div>
      <div>
        <h4>Add Log</h4>
      </div>

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {finalTextArea('log', 'Log')}
            {okCancel(onOkClick)}
          </form>
        )}
      />
    </div>
  );
}
