import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCalendarEvent } from '../../../redux/actions/Events/event.actions';
import { Table } from 'react-bootstrap';
import SJCourseInfoTableRow from './SJCourseInfoTableRow';
import HelmetTracker from '../../core/HelmetTracker';

export default function SJCourseInfoTable() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchCalendarEvent(id));
    }
  }, [dispatch, id]);

  const eventSelected  = useSelector(
    (state) =>
      state.calendar.find((m) => {
        return m.id === parseInt(id);
      }),
    shallowEqual
  );

  function tableEvents(eventSelected) {
    var i = 0;
    if (eventSelected) {
      return eventSelected.sjCourses.map((sjCourse) => {
        i++;
        return <SJCourseInfoTableRow key={i} sjCourse={sjCourse} />;
      });
    }
  }

  const objId = id ? id : null;

  return (
    <>
      <HelmetTracker title="SJ Info" objId={objId} />
      <Table striped bordered overflow hover>
        <thead>
          <tr>
            <th>Level</th>
            <th>Meters</th>
            <th>Speed</th>
            <th>Time</th>
            <th>Max Time</th>
          </tr>
        </thead>
        <tbody>{tableEvents(eventSelected)}</tbody>
      </Table>
    </>
  );
}
