/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addDocToHorse,
} from '../../../../redux/actions/horse.actions';
import {
  Form,
  Card,
  Button,
} from 'react-bootstrap';
import {
  textField,
  intSelector,
  photoUploader,
} from '../../../core/CoreForms';
import HorseDocumentGroup from './HorseDocumentGroup';
import { PlusCircle } from 'react-bootstrap-icons';

const DOCUMENT_CATEGORY_HORSES = 1;

class HorseDocumentManagementPanel extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmitDocument = this.handleSubmitDocument.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      id: this.props.horse ? this.props.horse.id : null,
      userId: this.props.horse ? this.props.horse.userId : null,
      owner: this.props.horse ? this.props.horse.owner : '',
      name: this.props.horse ? this.props.horse.name : '',
      feed: this.props.horse ? this.props.horse.feed : '',
      photoDescription: '',
      videoDescription: '',
      videoTitle: '',
      videoURL: '',
      photoURL: this.props.horse
        ? this.props.horse.photoURL
        : '/images/horses/horse_avatar.jpg',
      errors: '',
      docDescription: '',
      docTitle: '',
      docType: 0,
      loading: false,
      adding: false,
    };
  }

  handleClick = (e) => {
    this.setState({
      adding: !this.state.adding,
    });
  }

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmitDocument = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { docDescription, docTitle, docType } = this.state;
    if (!(this.props.horse && this.props.horse.id)) {
      return;
    }
    const data = new FormData();
    data.append('description', docDescription);
    data.append('title', docTitle);
    data.append('category', DOCUMENT_CATEGORY_HORSES);
    data.append('userId', this.props.auth.id);
    data.append('horseId', this.props.horse.id);
    data.append('type', docType);

    for (
      let i = 0, numFiles = e.target.photos.files.length;
      i < numFiles;
      i++
    ) {
      let file = e.target.photos.files[i];
      data.append('docs', file, file.name);
    }

    this.setState({ docDescription: '', docTitle: '', loading: true });
    e.target.photos.value = null; // blanks the file
    this.props
      .addDocToHorse(data)
      .catch((err) =>
        err.response
          .json()
          .then(({ errors }) => this.setState({ errors, loading: false }))
      );
  };
  /* eslint-disable max-len */
  /*
   *  1 - Horse Documents
   *     TYPES:
   *         0 - General
   *         1 - Coggins
   *         2 - Vet Document
   *         3 - Health Cert
   *         4 - Pedigree
   *         5 - Contract
   * */
  docTypes = [
    'General',
    'Coggins',
    'Vet Document',
    'Health Cert',
    'Pedigree',
    'Contract',
  ];

  getForm() {
    if (this.state.adding) {
      return (
        <div>
          <h3>Add Document</h3><Form
            onSubmit={this.handleSubmitDocument}
          >
            {textField('docTitle', 'Title', this)}
            {textField('docDescription', 'Description', this)}
            {intSelector('docType', 'Doc Type', this, this.docTypes)}
            {photoUploader()}
            <Button type="submit" variant="dark">
              Submit
            </Button>
          </Form>
        </div>
      )
    }
  }

  render() {


    var docPanel;

    if (this.props.horse) {
      docPanel = <HorseDocumentGroup horse={this.props.horse} />;
    } else {
      docPanel = <label>DocPanelLabel</label>;
    }

    return (
      <Card bg="info">
        <Card.Header>
          <h2>Documents&nbsp;&nbsp;
            <Button variant="info" onClick={this.handleClick}>
              <PlusCircle />
            </Button>
          </h2>
        </Card.Header>
        <Card.Body>
          {this.getForm()}
        </Card.Body>
        <Card.Footer>
          {docPanel}
        </Card.Footer>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  addDocToHorse,
})(HorseDocumentManagementPanel);