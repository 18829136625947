import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import {
  finalTextField,
  finalObjSelector,
  renderFormTitle,
  okButton,
  required,
  createOptionsList,
} from '../core/FinalCoreForms';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDivision,
  updateDivision,
  fetchEventLevels,
} from '../../redux/actions/Events/entry.actions';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const DivisionForm = ({ division, onOkClick }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEventLevels());
  }, [dispatch]);

  const eventLevels = useSelector((state) => state.eventLevels);

  function submitDivision(division) {
    if (division && division.id) {
      return dispatch(updateDivision(division)).then(() => {
        //   navigateBack();
      });
    } else {
      return dispatch(createDivision(division)).then(() => {
        //   navigateBack();
      });
    }
  }

  const initialValues = {
    id: division && division.id ? division.id : null,
    name: division && division.name ? division.name : '',
    type: division && division.type ? division.type : 0,
    eventLevelId:
      division && division.eventLevelId
        ? division.eventLevelId
        : 0,
  };

  const onSubmit = async (values) => {
    await sleep(300);
    if (division) {
      Object.assign(division, values);
      submitDivision(division);
    } else {
      let newData = {};
      Object.assign(newData, values);
      submitDivision(newData);
    }
  };

  const typeOptionsList = [
    { key: 'Not Selected', value: '0' },
    { key: 'Horse Trials', value: '1' },
    { key: 'Combined Test', value: '2' },
    { key: 'Dressage Only', value: '3' },
  ];

  return (
    <div>
      {renderFormTitle(division, 'Division')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {finalTextField('name', 'Division Name', required)}
            {finalObjSelector('type', 'Type', typeOptionsList)}
            {finalObjSelector('eventLevelId', 'Level', createOptionsList(eventLevels))}

            {okButton()}
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    </div>
  );
};

export default DivisionForm;
